define([
  'jquery',
  'underscore',
  'backbone',
  './../components/upx',
  'modules/common/components/managers/cache',
  'modules/common/components/connection',
  '../events/error/offline',
  'crypto-js',
  'modules/common/components/promisify',
  'modules/common/components/moment',
  'lodash/cloneDeep',
],
($, _, Backbone, UPX, CacheManager, Connection, OfflineEvent, CryptoJS, Promisify, Moment, cloneDeep) => {
  function getProperty(object, property) {
    if (object == null) return void 0;
    const value = object[property];
    return (typeof value === 'function') ? object[property]() : value;
  }

  return Backbone.Collection.extend({

    initialize() {
      this.wrapper = UPX;

      this.cache = !!this.cache;
      this.cacheOptions = this.cacheOptions || {};
      if (this.cache) {
        this.enableCache();
      }

      this.offline = !!this.offline;
      this.offlineOptions = this.offlineOptions || {};
      if (this.offline) {
        this.enableOffline();
      }
    },
    /**
             *
             * @param options
             * @returns {null|*|model.cacheStorage}
             */
    getCacheStorage() {
      if (this.cache && this.cacheStorage == null) {
        this.cacheStorage = CacheManager.getStore(
          `C_${this.module}_${this.object}`,
          this.cacheOptions,
        );
      }
      return this.cacheStorage;
    },
    /**
             *
             */
    disableCache() {
      this.cache = false;
      this.cacheStorage = null;
    },
    /**
             *
             * @param options
             */
    enableCache(options) {
      this.cacheOptions = options || this.cacheOptions;
      this.cache = true;
    },
    /**
             *
             * @param options cache
             */
    enableOffline(options) {
      this.offline = true;
      if (options) {
        this.offlineOptions = options;
      }
      if (!this.cache) {
        this.enableCache();
      }
    },
    /**
             *
             * @param options cache
             */
    disableOffline() {
      this.offline = false;
    },
    parse(response) {
      if (response.success && response.response !== undefined) {
        this.setCount(response.response.count);
        this.setTotal(response.response.total);
        return response.response.data;
      }
      return {};
    },

    setTotal(total) {
      this.total = total;
    },

    setCount(count) {
      this.count = count;
    },

    setStart(start) {
      this.start = start;
    },

    getStart() {
      let start = 0;
      const parsedStart = parseInt(this.start);

      if (typeof (parsedStart) === 'number' && !isNaN(parsedStart)) {
        start = parsedStart;
      } else {
        console.warn(`Start was not a number, thus defaults to 0, was: ${this.start}`);
      }
      return start;
    },

    setLimit(limit) {
      this.limit = limit;
    },

    getLimit() {
      let limit = 50;
      const parsedLimit = parseInt(this.limit);
      if (typeof (parsedLimit) === 'number' && !isNaN(parsedLimit)) {
        limit = parsedLimit;
      } else {
        console.warn(`Limit was not a number, thus defaults to 50, was: ${this.limit}`);
      }
      return limit;
    },

    canFetchNext() {
      return this.start + this.limit < this.total && this.total > 0;
    },

    canFetchPrevious() {
      return this.start > 0 && this.models.length < this.start + this.models.length && this.total > 0;
    },

    fetchNext() {
      const options = {
        params: this.params || {},
        remove: false,
      };

      options.params.start = this.start + this.count;
      return this.fetch(options);
    },

    fetchPrevious() {
      const options = {
        params: this.params || {},
        remove: false,
      };

      options.params.start = this.start - this.limit;

      if (options.params.start < 0) {
        options.params.start = 0;
        options.params.limit = this.start - 1;
      }

      return this.fetch(options);
    },

    sync(method, model, options) {
      const parameters = options.params || {};
      if (parameters.limit !== undefined && parameters.limit != this.limit) {
        this.setLimit(parameters.limit);
      }
      if (parameters.start !== undefined && parameters.start != this.start) {
        this.setStart(parameters.start);
      }
      parameters.start = this.getStart();
      parameters.limit = this.getLimit();

      this.params = parameters;

      if (method == 'read' && model.cache) {
        return this.readFromCache(method, model, options);
      }
      return this.syncFromUpx(method, model, options);
    },

    readFromCache(method, model, options) {
      options = options || {};
      const self = this;
      const store = this.getCacheStorage();
      const syncDfd = $.Deferred();

      const queryId = CryptoJS.MD5(JSON.stringify(this.params)).toString();

      const upxCall = function () {
        const oldSuccess = options.success;
        options.success = function (resp) {
          if (resp.success !== undefined && resp.success) {
            // success lets save the response
            store.write(queryId, resp);
          }
          if (oldSuccess) {
            oldSuccess(resp);
          }
          syncDfd.resolve(resp.response);
        };
        const oldError = options.error;
        options.error = function (a, b, c) {
          if (oldError) {
            oldError(a, b, c);
          }
          syncDfd.reject(a, b, c);
        };
        self.syncFromUpx(method, model, options);
      };

      store.lookup(queryId).then((cacheResp) => {
        // there is cached object
        if (
          store.isFresh(cacheResp) // it is a fresh one
                            || (self.offline && !Connection.isOnline())// not fresh but offline mode
        ) {
          const resp = cacheResp.get('value');
          if (options && options.success) {
            options.success(resp);
          }
          syncDfd.resolve(resp);
        } else {
          // cache object not good we need to call upx
          // syncDfd will be resolved or rejected by upxCall
          upxCall();
        }
      }, upxCall, // no cache call the upx call
      );

      return syncDfd;
    },

    syncFromUpx(method, model, options) {
      if (method == 'read') {
        if (this.collection_method == undefined) {
          throw new Error(
            `No collection method for ${
              this.module}::${this.object}`,
          );
        }

        if (!Connection.isOnline()) {
          const def = $.Deferred();
          def.reject('Cannot make upx call: no connection');

          const ev = new OfflineEvent();
          ev.trigger();

          return def;
        }

        return this.wrapper.call(
          this.module,
          this.collection_method,
          this.params,
          options,
        );
      }
      throw new Error(`Sync method ${method} not supported for UpxCollection`);
    },

    /**
             * Fetches using the options passed untill there is nothing fetchable anymore
             */
    fetchAll(options) {
      const def = new $.Deferred();

      // Get the item limit
      const limit = options.limit || null;
      if (limit) delete options.limit;

      // Initial fetch
      this.fetch(options)
        .then(() => {
          // fetch untill you can't fetch anymore!
          this._fetchAll({ limit })
            .then(def.resolve, def.reject);
        }, def.reject);

      return def;
    },

    /**
             * Recursive function
             * @private
             */
    _fetchAll({ def, limit }) {
      def = def || new $.Deferred();
      limit = limit || this.total;

      if (
      // Check if we need to fetch more
        this.length < limit
                    // Check if there is more to fetch
                    && this.canFetchNext()
      ) {
        // Fetch more
        this.fetchNext()
          .then(() => {
            // Once done. call it's self
            this._fetchAll({ def, limit });
          }, def.reject);
      }
      // Nothing to fetch anymore > done fetching
      else {
        def.resolve();
      }

      return def;
    },

    /**
             * If the collection is loaded or not.
             * @private
             */
    _loaded: false,

    /**
             * Wrapper for the private _loaded variable
             * @return {boolean}
             */
    isLoaded() {
      return this._loaded;
    },

    /**
             * The timestamp of when it was loaded
             */
    _loadedDate: 0,

    /**
             * Wrapper for the price _loadedDate varaible
             * @return {number}
             */
    getLoadedTimestamp() {
      return this._loadedDate;
    },

    /**
             * Default update filter name
             */
    loadUpdateFilterName: 'date_updated__>=',

    /**
             * Default sort name
             */
    loadSortName: 'id',

    /**
             * The parameters used to load the collection
             * @override to change
             */
    loadParameters: {
      start: 0,
      limit: 250,
    },

    /**
             * Loads the collection
             * @param reload {boolean} If the collection unloads before loading
             * @param update {boolean} If the collection only fetches updated items
             * @return {$.Deferred}
             */
    load({ reload = false, update = false } = {}) {
      const def = new $.Deferred();

      // Unload the collection will cause it to load again, AKA reload.
      if (reload) this.unload();

      if (this._loaded && !update) {
        // Resolve if the collection is already loaded
        def.resolve();
      } else {
        // get pre-fetch date to ensure updates are working correctly
        const fetchDate = (new Date()).getTime();

        // Get the parameters
        const params = this._getLoadParameters({ update });

        // Else we fetch it
        this.fetchAll({
          params, add: true, merge: true, remove: false,
        })
          .then(() => {
            // Mark the collection as loaded
            this._loaded = true;
            // Set date
            this._loadedDate = fetchDate;
            // Done
            def.resolve();
          }, def.reject);
      }

      return def;
    },

    _getLoadParameters({ update }) {
      // cloneDeep is required to prevent the filters and sort to be polluted.
      const parameters = cloneDeep(this.loadParameters);

      const ensureArray = (name) => {
        if (!_.isArray(parameters[name])) parameters[name] = [];
      };

      // Add sort
      ensureArray('sort');
      parameters.sort.push({
        name: this.loadSortName,
        dir: 'asc',
      });

      // Add update filters
      if (update && this._loaded) {
        ensureArray('filters');
        parameters.filters.push({
          name: this.loadUpdateFilterName,
          val: new Moment(this._loadedDate).format(), // Ensure the time has the timezone.
        });
      }

      return parameters;
    },

    /**
             * @return {Promise}
             */
    loadPromise({ reload = false, update = false } = {}) {
      const deferred = this.load.apply(this, arguments);
      return Promisify.deferredToPromise(deferred);
    },

    fetchPromise(...args) {
      const deferred = this.fetch(...args);
      return Promisify.deferredToPromise(deferred);
    },
    /**
             * Unloads the collection
             */
    unload() {
      this.reset();
      this._loaded = false;
      this._loadedDate = 0;
    },

    /**
             * Similar to clone, but ensures the models are also cloned
             */
    copy() {
      // Copy data
      const copy = this.clone();
      const modelArray = this.toJSON();
      // Update copy
      copy.reset(modelArray);
      // done
      return copy;
    },

  });
});
