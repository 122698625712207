define([
  'jquery',
  'backbone',
  'backbone.memento',
  './../components/upx',
  './../components/struct',
  'modules/common/components/nested',
  'underscore',
  'modules/common/components/managers/cache',
  'modules/common/components/connection',
  '../events/error/offline',
  'modules/common/components/promisify',
  'backbone.deepmodel',
], ($, Backbone, Memento, UPX, Struct, Nested, _, CacheManager, Connection, OfflineEvent, Promisify) => {
  // Wrap an optional error callback with a fallback error event.
  const wrapError = function (model, options) {
    const { error } = options;
    options.error = function (resp) {
      if (error) error(model, resp, options);
      model.trigger('error', model, resp, options);
    };
  };

  function getProperty(object, property) {
    if (object == null) return void 0;
    const value = object[property];
    return (typeof value === 'function') ? object[property]() : value;
  }

  const model = Backbone.DeepModel.extend({
    /**
         *
         */
    disableCache() {
      this.cache = false;
      this.cacheStorage = null;
    },
    /**
         *
         * @param options
         */
    enableCache(options) {
      this.cacheOptions = options || this.cacheOptions;
      this.cache = true;
    },
    /**
         *
         * @param options cache
         */
    enableOffline(options) {
      this.offline = true;
      if (options) {
        this.offlineOptions = options;
      }
      if (!this.cache) {
        this.enableCache();
      }
    },
    /**
         *
         * @param options
         * @returns {null|*|model.cacheStorage}
         */
    getCacheStorage(options) {
      if (this.cache && this.cacheStorage == null) {
        this.cacheStorage = CacheManager.getStore(
          `M_${this.module}_${this.object}`,
          this.cacheOptions,
        );
      }
      return this.cacheStorage;
    },
    /**
         *
         * @param options cache
         */
    disableOffline() {
      this.offline = false;
    },
    /**
         *
         * @param options
         */
    initialize(options) {
      this.wrapper = UPX;
      _.extend(this, new Memento(this));

      if (this.struct == undefined) {
        this.struct = Struct.read(this.module, this.object);
      }

      this.crud = this.crud || {};
      this.mapping = this.mapping || {};

      this.crud = $.extend({}, this.struct.crud, this.crud);
      this.mapping = $.extend({}, this.struct.mapping, this.mapping);

      this.cache = !!this.cache;
      this.cacheOptions = this.cacheOptions || {};
      if (this.cache) {
        this.enableCache();
      }

      this.offline = !!this.offline;
      this.offlineOptions = this.offlineOptions || {};
      if (this.offline) {
        this.enableOffline();
      }
    },
    /**
         *
         * @param response
         * @returns {*}
         */
    parse(response) {
      if (response.success !== undefined && !response.success) {
        return {};
      } if (response.response) {
        return response.response;
      }
      return response;
    },
    /**
         *
         * @param method
         * @param model
         * @param ajaxOptions
         * @returns {*}
         */
    sync(method, model, ajaxOptions) {
      if (method == 'read' && model.cache) {
        return this.readFromCache(method, model, ajaxOptions);
      }
      return this.syncFromUpx(method, model, ajaxOptions);
    },

    _quoteRegExp(str) {
      return (`${str}`).replace(/[.?*+^$[\]\\(){}|-]/g, '\\$&');
    },
    _convertToBool(val) {
      if (typeof (val) === 'string') {
        if (val == 'true' || val == '1') {
          val = true;
        } else if (val == 'false' || val == '0') {
          val = false;
        }
      }
      return val;
    },
    /**
         *
         * @param method
         * @param model
         * @param ajaxOptions
         * @returns {*}
         */
    syncFromUpx(method, model, ajaxOptions) {
      const self = this;
      const parameters = ajaxOptions.parameters || {};

      const realMethod = model.crud[method];
      if (realMethod == undefined) {
        throw new Error(`Crud ${method} is undefined for ${model.module}::${model.object}`);
      }
      let isUpdate = false;
      let dummyCall = false; // for update when nothing changed no call needed

      if (method == 'update') {
        const fields = model.mapping[realMethod];
        const fieldsToAdd = model.changes(); // all fields

        isUpdate = true;
        dummyCall = true; // we don`t know if anything changed yet

        const changes = Nested.objToPaths(fieldsToAdd);

        $.each(fields, (name, options) => {
          if (options.mapping) {
            // try to find only those with proper mapping set
            const arrayMatch = options.type.match(/^array<([\\\w]+)>$/);
            const isArray = false;
            let found = name in changes;
            if (arrayMatch && arrayMatch.length) {
              // it`s an array
              // search for prefix
              const r = new RegExp(`^${self._quoteRegExp(name)}\\.(\\d+)`);
              $.each(changes, (key) => {
                const m = key.match(r);
                if (m && m.length) {
                  found = true;
                  return false;
                }
              });
            }

            if (options.required || found) {
              const val = model.get(name);
              Nested.setNested(parameters, options.mapping, model.get(name));
              if (options.type == 'bool') {
                model.set(name, self._convertToBool(val));
              }

              if (found) {
                dummyCall = false; // there are changes
              }
            }
          }
        });
      }
      let def;
      if (!dummyCall) {
        def = this.callObjectMethod(realMethod, parameters, ajaxOptions);
      } else {
        def = $.Deferred().resolve();
      }
      if (isUpdate) {
        def.then(() => {
          model.store();
        });
      }
      return def;
    },
    /**
         *
         * @param method
         * @param model
         * @param options
         * @returns {*}
         */
    readFromCache(method, model, options) {
      options = options || {};
      const self = this;
      const store = this.getCacheStorage();

      const syncDfd = $.Deferred();
      const upxCall = function () {
        const oldSuccess = options.success;
        const oldError = options.error;
        options.error = function (a, b, c) {
          if (oldError) {
            oldError(a, b, c);
          }
          syncDfd.reject(a, b, c);
        };
        options.success = function (resp) {
          if (resp.success !== undefined && resp.success) {
            // success lets save the response
            store.write(model.id, self.parse(resp));
            if (oldSuccess) {
              oldSuccess(resp);
            }
            syncDfd.resolve(resp);
          } else {
            // error
            syncDfd.reject(resp);
          }
        };

        self.syncFromUpx(method, model, options);
      };

      store.lookup(model.id).then((cacheResp) => {
        // there is cached object
        if (
          store.isFresh(cacheResp) // it is a fresh one
                    || (self.offline && !Connection.isOnline())// not fresh but offline mode
        ) {
          const resp = cacheResp.get('value');
          if (options && options.success) {
            options.success(resp);
          }
          syncDfd.resolve(resp);
        } else {
          // cache object not good we need to call upx
          // syncDfd will be resolved or rejected by upxCall
          upxCall();
        }
      }, upxCall, // no cache call the upx call
      );

      return syncDfd;
    },
    /**
         *
         * @param module
         * @param method
         * @param parameters
         * @param ajaxOptions
         * @returns {*}
         */
    call(module, method, parameters, ajaxOptions) {
      if (module === undefined) {
        throw new Error('UPX Module needs to be set.');
      }

      if (method === undefined) {
        throw new Error('UPX Method needs to be set.');
      }

      ajaxOptions = ajaxOptions || {};

      if (parameters === undefined) {
        parameters = {};
      }

      if (!Connection.isOnline()) {
        const def = $.Deferred();
        def.reject('Cannot make upx call: no connection');

        const ev = new OfflineEvent();
        ev.trigger();

        return def;
      }
      return this.wrapper.call(module, method, parameters, ajaxOptions);
    },
    /**
         *
         * @param method
         * @param parameters
         * @param ajaxOptions
         * @returns {*}
         */
    callObjectMethod(method, parameters, ajaxOptions) {
      if (!parameters || $.isEmptyObject(parameters)) {
        parameters = {};
        // copy only if empty
        const mapping = this.mapping[method];
        const self = this;
        const interModel = new Backbone.DeepModel();

        $.each(mapping, (key, map) => {
          const data = self.get(key);
          if (data !== undefined) {
            interModel.set(map.mapping, data);
          }
        });
        $.extend(true, parameters, interModel.attributes);
      }

      return this.call(
        this.module,
        method,
        parameters,
        ajaxOptions,
      );
    },
    // Destroy this model on the server if it was already persisted.
    // Optimistically removes the model from its collection, if it has one.
    // If `wait: true` is passed, waits for the server to respond before removal.
    destroy(options) {
      options = options ? _.clone(options) : {};
      const model = this;
      const { success } = options;

      const destroy = function () {
        model.trigger('destroy', model, model.collection, options);
      };

      options.success = function (resp) {
        if (options.wait || model.isNew()) {
          if (resp.success) {
            destroy();
          }
        }
        if (success) success(model, resp, options);
        if (!model.isNew()) model.trigger('sync', model, resp, options);
      };

      if (this.isNew()) {
        options.success();
        return false;
      }
      wrapError(this, options);

      const xhr = this.sync('delete', this, options);
      if (!options.wait) destroy();
      return xhr;
    },

    /**
         * @return {Promise}
         */
    savePromise() {
      const deferred = this.save.apply(this, arguments);
      return Promisify.deferredToPromise(deferred);
    },

    /**
         * @return {Promise}
         */
    destroyPromise() {
      const deferred = this.destroy.apply(this, arguments);
      return Promisify.deferredToPromise(deferred);
    },

    /**
         * @return {Promise}
         */
    fetchPromise() {
      const deferred = this.fetch.apply(this, arguments);
      return Promisify.deferredToPromise(deferred);
    },

  });

  return model;
});
