define((require) => {
  // get the application settings
  const application = require('application');
  const { settings } = application;

  // modules we should start
  const modules = [
    'common',
    'pwa',
    'upx',
  ];

  // Not loading the profile module on the customer makes the profileModule not to load,
  // something that is not needed in the customer-screen
  if (location.hash !== '#customer-screen') {
    modules.push('profile');
    settings.title = 'StoreKeeper | cash-register';
  } else {
    settings.title = 'StoreKeeper | customer-display';
  }

  modules.push('shop.cash-register-retail');

  if (!settings.admin) {
    settings.admin = {};
  }
  settings.admin.disabledRouters = [
    'authentication',
    'profile',
  ];
  settings.admin.locales = [
    'nl', 'en', 'de',
  ];

  settings.admin.sidebar = {
    title: 'StoreKeeper',
    icon: ' ',
    description: 'cash-register',
  };

  if (!settings.upx) { settings.upx = {}; }
  if (!('client_name' in settings.upx)) {
    // set client name
    let clientName = 'CashRegister';
    // get uuid
    const uuid = getQueryVariable('uuid');
    // get Route
    let screenType = 'cashier';
    if (location.hash === '#customer-screen') {
      screenType = 'customer';
    }

    // building client name
    clientName += ` Screen:${screenType}`;
    if (uuid) {
      clientName += ` UUID:${uuid}`;
    }
    // Setting in UPX config
    settings.upx.client_name = clientName;
  }

  require('suite/styles/index.less');

  // Function source: https://stackoverflow.com/a/2091331
  function getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    console.log('Query variable %s not found', variable);
  }

  // start the modules
  const loadModule = (module) => require(`modules/${module}/application`);

  return async () => {
    // start the modules
    for (const i in modules) {
      const key = modules[i];
      const app = loadModule(key);
      const appSettings = settings[key] || {};
      const result = app.start(appSettings);
      if (result instanceof Promise) {
        await result;
      }
    }
  };
});
