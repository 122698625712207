define([
  'backbone',
  'jquery',
  'modules/common/behaviors/behavior',
  'underscore',
  'modules/common/components/locale',
  'backbone.validation',
],
(Backbone, $, Behavior, _, Locale) => Behavior.extend({
  defaults: {
    forceUpdate: true,
    model: false,
    method: false,
    autoCreateHelperBlocks: true,
    nameSelector: 'name',
    nameSelectorFn: null,
    labelRequireContent: '',
  },

  initialize(options) {
    options = options || {};
    if (options.validationCls) {
      this.validationCls = options.validationCls;
    } else {
      this.validationCls = Backbone.Validation;
    }
    this.autoCreateHelperBlocks = true;
    if ('autoCreateHelperBlocks' in options) {
      this.autoCreateHelperBlocks = !!options.autoCreateHelperBlocks;
    }

    this.nameSelectorFn = null;
    if ('nameSelectorFn' in options) {
      let fn = options.nameSelectorFn;
      if (typeof fn === 'string') {
        fn = this.view[fn];
      }
      this.nameSelectorFn = _.bind(fn, this.view);
    }
    this.labelRequireContent = '';
    if ('labelRequireContent' in options) {
      this.labelRequireContent = options.labelRequireContent.toString();
    }

    // overwrite the marking functions if provided
    this.attachFn('markFieldElInvalid', options);
    this.attachFn('markFieldElValid', options);

    // The backbone.validation does it's own replacement
    // but we need to generate the params so that the icu translation doesn't fail
    const paramGen = (n) => {
      n = n || 0;
      const params = {};
      for (let i = 0; i <= n; i++) {
        params[i] = `{${i}}`;
      }
      return params;
    };

    _.extend(this.validationCls.messages, {
      required: Locale.translate('{0}_is_required', paramGen()),
      acceptance: Locale.translate('{0}_must_be_accepted', paramGen()),
      min: Locale.translate('{0}_must_be_greater_than_or_equal_to_{1}', paramGen(1)),
      max: Locale.translate('{0}_must_be_less_than_or_equal_to_{1}', paramGen(1)),
      range: Locale.translate('{0}_must_be_between_{1}_and_{2}', paramGen(2)),
      length: Locale.translate('{0}_must_be_{1}_characters', paramGen(1)),
      minLength: Locale.translate('{0}_must_be_at_least_{1}_characters', paramGen(1)),
      maxLength: Locale.translate('{0}_must_be_at_most_{1}_characters', paramGen(1)),
      rangeLength: Locale.translate('{0}_must_be_between_{1}_and_{2}_characters', paramGen(2)),
      oneOf: Locale.translate('{0}_must_be_one_of_{1}', paramGen(1)),
      equalTo: Locale.translate('{0}_must_be_the_same_as_{1}', paramGen(1)),
      digits: Locale.translate('{0}_must_only_contain_digits', paramGen()),
      number: Locale.translate('{0}_must_be_a_number', paramGen()),
      email: Locale.translate('{0}_must_be_a_valid_email', paramGen()),
      url: Locale.translate('{0}_must_be_a_valid_url', paramGen()),
      urlRelaxed: Locale.translate('{0}_must_be_a_valid_url', paramGen()),
      twitterHandler: Locale.translate('{0}_must_be_a_valid_twitter_handler', paramGen()),
      facebookProfileUrl: Locale.translate('{0}_must_be_a_valid_facebook_profile_url', paramGen()),
      linkedinProfileUrl: Locale.translate('{0}_must_be_a_valid_linkedin_profile_url', paramGen()),
      taskDateInput: Locale.translate('{0}_contains_an_invalid_date_format', paramGen()),
      inlinePattern: Locale.translate('{0}_is_invalid', paramGen()),
      time: Locale.translate('{0}_should_be_a_valid_time', paramGen()),
    });

    // add url with optional schema prefix
    this.validationCls.patterns.urlRelaxed = /^((https?|ftp):\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
    // Checks if the correct Twitter handler is used
    this.validationCls.patterns.twitterHandler = /^@[a-zA-Z0-9_]{1,15}$/;
    // Checks for a valid Facebook profile url
    this.validationCls.patterns.facebookProfileUrl = /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/g;
    // Checked for a valid Linkedin Profile url
    this.validationCls.patterns.linkedinProfileUrl = /(ftp|http|https):\/\/?((www|\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/g;
    // from / to datetime
    this.validationCls.patterns.taskDateInput = /(\d{1,2})\/(\d{1,2})\/(\d{2,4})\ (\d{1,2})\:(\d{1,2})|(\d{1,2})\/(\d{1,2})\/(\d{2,4})\ (\d{1,2})\:(\d{1,2})\ \-\ (\d{1,2})\/(\d{1,2})\/(\d{2,4})\ (\d{1,2})\:(\d{1,2})/;
    // from / to datetime
    this.validationCls.patterns.time = /^\s*(\d{1,2})\s*\:\s*(\d{1,2})\s*([aApP][mM])?\s*$/;

    this.validationCls.configure({
      labelFormatter: 'label',
    });
  },

  attachFn(name, options) {
    this[`${name}Fn`] = null;
    if (`${name}Fn` in options) {
      let fn = options[`${name}Fn`];
      if (typeof fn === 'string') {
        fn = this.view[fn];
      }
      this[`${name}Fn`] = _.bind(fn, this.view);
    }
  },

  onRender() {
    const model = this.options.model || this.view.model;

    this.setRules(model);
    this.setLabels(model);
    this.bind(model);
  },

  onDestroy() {
    this.validationCls.unbind(this.view);
  },

  setLabels(model) {
    model.labels = model.labels || {};
    if (this.options.labels !== undefined) {
      $.extend(model.labels, this.options.labels);
    }
    const self = this;
    $.each(model.validation, (name, config) => {
      // search for labels
      if (!(name in model.labels)) {
        const field = self.getFieldEl(self.view, name);
        if (field.length) {
          // field found
          const label = field.parents('.form-group').find('label');
          model.labels[name] = label.text().replace(/[\*\s]+$/, '');
        }
      }
    });
  },

  setRules(model) {
    const rules = {};

    const { mapping } = model;
    const fields = mapping[this.options.method];

    if (!fields) {
      console.error(`mapping not found for "${this.options.method}"`);
      return;
    }

    $.each(fields, (name, options) => {
      const fieldRules = 'validationRules' in options ? options.validationRules : {};

      if (options.required) {
        fieldRules.required = true;
      } else {
        fieldRules.required = false;
      }

      if (options.type == '\\Semail') {
        fieldRules.pattern = 'email';
      }

      if (!$.isEmptyObject(fieldRules)) {
        rules[name] = fieldRules;
      }
    });

    // add rules defined on Validation behaviour init
    if (this.options.rules !== undefined) {
      const optionRules = $.extend(true, {}, this.options.rules);
      $.each(optionRules, (name, options) => {
        // bind the view validation if needed
        if (options.fn && _.isString(options.fn) && options.fn.startsWith('view.')) {
          const fnName = options.fn.substr(5);
          const fn = this.view[fnName];
          if (fn) {
            options.fn = _.bind(fn, this.view);
          }
        }
      });

      $.extend(rules, optionRules);
    }

    model.validation = rules;
  },

  markFieldValid(view, attr) {
    const field = this.getFieldEl(view, attr);
    if (field.length > 0) {
      if (this.markFieldElValidFn) {
        this.markFieldElValidFn(field);
      } else {
        this.markFieldElValid(field);
      }
    }
  },

  markFieldElValid($el) {
    const formGroup = $el.closest('.form-group');
    formGroup.removeClass('has-error');

    const helpBlock = this._getFieldHelperBlock($el);
    helpBlock.addClass('hidden');
  },

  getFieldEl(view, name) {
    let { $el } = view;
    if (this.options.el) {
      $el = $el.find(this.options.el);
    }
    if (this.nameSelectorFn) {
      return this.nameSelectorFn(name, this.options.nameSelector);
    }
    return $el.find(`[${this.options.nameSelector}="${name}"]`);
  },

  markFieldInvalid(view, attr, error) {
    const field = this.getFieldEl(view, attr);
    if (field.length > 0) {
      if (this.markFieldElInvalidFn) {
        this.markFieldElInvalidFn(field, error);
      } else {
        this.markFieldElInvalid(field, error);
      }
    }

    console.warn(`Model validation error (attr: ${attr}): ${error}`);
  },

  markFieldElInvalid($el, error) {
    const formGroup = $el.closest('.form-group');
    formGroup.addClass('has-error');

    const helpBlock = this._getFieldHelperBlock($el);
    helpBlock.html(error);
    helpBlock.removeClass('hidden');
  },

  _getFieldHelperBlock(field) {
    let parent = field.parent();

    if (parent.hasClass('input-group')) {
      parent = parent.parent();
    }

    let helpBlock = parent.find('.help-block');

    if (helpBlock.length == 0) {
      helpBlock = parent.parent().find('.help-block');
    }

    if (helpBlock.length == 0) {
      if (this.autoCreateHelperBlocks) {
        helpBlock = $('<div class="help-block"></div>');
        parent.append(helpBlock);
      }
    }
    return helpBlock;
  },

  bind(model) {
    const self = this;

    this.validationCls.bind(this.view, {
      valid(view, attr) {
        self.markFieldValid(view, attr);
      },
      invalid(view, attr, error) {
        self.markFieldInvalid(view, attr, error);
      },
      model,
      forceUpdate: !!this.options.forceUpdate,
      selector: this.options.nameSelector || 'name',
    });
  },

  onShow() {
    this.applyLabelRequireContent();
  },

  applyLabelRequireContent() {
    if (this.labelRequireContent !== '') {
      // create required element
      const requiredElement = document.createElement('span');
      requiredElement.classList.add('text-danger');
      requiredElement.innerText = `${this.labelRequireContent} `;

      // Find all fields
      const { el } = this.view;
      const model = this.options.model || this.view.model;
      _.each(model.validation, (options, value) => {
        if (options && 'required' in options && options.required) {
          // get label element
          let labelEl = el.querySelector(`label[for="${value}"]`);
          // Check if the label was already found
          if (!labelEl) {
            const input = el.querySelector(`[name="${value}"]`);
            // Input check
            if (input) {
              // Form group check
              const formGroupEl = input.closest('.form-group');
              if (formGroupEl) {
                labelEl = formGroupEl.querySelector('label');
              }
            }
          }

          if (labelEl) {
            labelEl.prepend(requiredElement.cloneNode(true));
          }
        }
      });
    }
  },

}));
